import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const adieuequateur = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Adieu Équateur" />
    <h3 className='underline-title'>Adieu Équateur!</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Adieu chaude Équateur<br />
        Équateur qui vis naître ton enfant<br />
        Ton enfant s'est évadé en vadrouilleur<br />
        Il est allé en vadrouille dans des montagnes implacables<br />
        Où le soleil même est enclavé entre monts et lac du flegme<br />
      </p>
      <p>
        Équateur natale<br />
        Ton fils ne te trahira pas<br />
        Dans son nouveau pays d'adoption forcée<br />
        Il apprend à se refroidir<br />
        Il s'exerce à l'indifférence<br />
        Il s'adapte à ton oubli<br />
      </p>
      <p>
        Et toi Équateur natale<br />
        Pendant ce temps-là<br />
        Tu chauffes encore jour et nuit<br />
        Ses copains d'enfance<br />
      </p>
      <p>
        Équateur natale<br /> 
        Tu n'as perdu aucun enfant<br />
        Il n'est qu'une brebis égarée<br />
        Qui n'a droit à aucune chaleur maternelle<br />
      </p>
      <p>
        Adieu Équateur<br />
        Tu ne rechaufferas point ton enfant<br />
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 10 juin 1976</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default adieuequateur
